import React from 'react';
import VersionDisclaimer from 'components/VersionDisclaimer/VersionDisclaimer';

const {
  PUBLIC_URL,
} = process.env;

/**
 * Error page component.
 * 
 * This is the general error page that corresponds to '/error' route.
 */
const Error = (props) => {
  return (
    <div className="error-page">
      <div className="logo">
        <img src={`${PUBLIC_URL}/images/Intellium_logo_360x75.png`} alt="Intellium Logo" />
      </div>

      <div className="box">
        <p><b>Error:</b></p>
        {props.children}
      </div>

      <VersionDisclaimer />
    </div>
  );
};

export default Error;
