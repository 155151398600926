import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Table,
} from "reactstrap";
import { API } from 'packages-ilis-ui';

const {
  REACT_APP_VERSION_SERVICE_HOST,
} = process.env;

class VersionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    this.setState({
      loading: true,
      error: null,
    }, () => {
      const serviceClient = new API('ui-gatewaycontainer-react', REACT_APP_VERSION_SERVICE_HOST);

      serviceClient.get('GetVersions')
        .then((response) => {
          this.setState({
            loading: false,
            entities: [
              ...response.result.items,
            ],
          });
        })
        .catch(({ traceId }) => {
          this.setState({
            error: `Error while trying to fetch the API version. TraceId: ${traceId}`,
            loading: false,
          });
        });
    });
  }

  render () {
    const { isOpen, toggleModal } = this.props;
    const { entities, loading, error } = this.state;

    return (
      <Modal className="versionDisclaimer-modal" isOpen={isOpen} toggle={toggleModal} size="md">
        <ModalHeader toggle={toggleModal}>Intellium</ModalHeader>
        <ModalBody>
          {
            !loading && error === null && (
              <Table bordered responsive size="sm">
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Version</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    entities.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {e.name}
                          </td>
                          <td>
                            { e.version || 'Unavailable.' }
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </Table>
            )
          }
          {
            loading && (
              <div className="d-flex flex-column text-center">
                <Spinner color="primary" className="align-self-center mb-2" />
                Loading...
              </div>
            )
          }
          {
            error !== null && (
              <div className="d-flex flex-column text-center">
                {error}
              </div>
            )
          }
        </ModalBody>
      </Modal>
    );
  }
}

export default VersionModal;
