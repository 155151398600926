import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import { initialize } from 'packages-ilis-ui';

// Local pages.
import LoginPage from '../pages/Login/Login';
import LogoutPage from '../pages/Logout/Logout';
import ErrorPage from '../pages/Error/Error';

/**
 * Root component.
 * 
 * It's the root component of the entire application.
 */
class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialization: false,
      error: false,
    };
  }

  componentDidMount() {
    initialize(this.props.initializationOptions)
      .then(() => {
        this.setState({
          initialization: true,
        });
      })
      .catch(() => {
        this.setState({
          initialization: true,
          error: true,
        });
      });
  }

  render() {
    if (!this.state.initialization) {
      return (
        <BlockUi tag="div" blocking={true}>
          <div style={{ height: '100vh' }} />
        </BlockUi>
      );
    }

    if (this.state.error) {
      return (
        <ErrorPage>
          <p>Unable to initialize the application.</p>
          <b>Please contact Millennium Health Support at 1.866.866.0605.</b>
        </ErrorPage>
      );
    }
    
    return (
      <BrowserRouter>
        <React.Fragment>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/logout" component={LogoutPage} />
            <Route path="/" render={() => <Redirect to="/login" />} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
};

export default Root;
