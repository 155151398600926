import React, { useState } from "react";
import { Button } from "reactstrap";

import * as pkg from './../../../package.json';
import { isHigherEnvironment } from 'helpers/environments';
import VersionModal from './VersionModal';

export const VersionDisclaimer = () => {
  return (
    <div className="versionDisclaimer-label">Intellium Version: {pkg.version}</div>
  );
}

export const VersionDisclaimerWithModal = () => {
  const [isOpen, setModal] = useState(false);
  const toggleModal = () => setModal(!isOpen);

  return (
    <div className="versionDisclaimer">
      <Button onClick={toggleModal} color="link">
        Intellium Version: {pkg.version}
      </Button>

      { isOpen && (
        <VersionModal
          isOpen={isOpen}
          toggleModal={toggleModal}
        />
      )}
    </div>
  );
}

export default isHigherEnvironment() ? VersionDisclaimer : VersionDisclaimerWithModal;
