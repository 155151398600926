import React from 'react';
import { withRouter } from "react-router";
import classNames from 'classnames';
import BlockUi from 'react-block-ui';
import querystring from 'query-string';

import { API, ExternalMessages } from 'packages-ilis-ui';
import OktaSignIn from './LoginWidget';
import VersionDisclaimer from 'components/VersionDisclaimer/VersionDisclaimer';

import 'react-block-ui/style.css';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const {
  REACT_APP_OKTA_HOST,
  REACT_APP_OKTA_CLIENT_ID,
  REACT_APP_LEGACY_HOST,
  REACT_APP_AUTHORIZATION_SERVICE_HOST,
  PUBLIC_URL,
  REACT_APP_DYNAMIC_REDIRECT
} = process.env;

/**
 * Steps enumeration.
 */
const Steps = {
  Widget: 'Widget',
  ForgotPassword: 'ForgotPassword',
};

/**
 * Login page component.
 * 
 * This is the login page that corresponds to '/login' route.
 */
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      currentStep: Steps.Widget,
      widgetStep: {
        widget: null,
        ...this.initialWidgetStepState(),
      },
      forgotPasswordStep: {
        ...this.initialForgotPasswordStepState(),
      },
    };
  }

  /**
   * Get the initial state for the WidgetStep.
   */
  initialWidgetStepState = () => {
    return {
      idToken: '',
      username: null,
      password: null,
      helplink: true,
      error: false,
      customError: null,
      callbackURL: this.getAction()
    };
  }

  /**
   * Get the initial state for the ForgotPasswordStep.
   */
  initialForgotPasswordStepState = () => {
    return {
      helplinkExpanded: false,
      forgotPasswordError: false,
      customError: null,
      username: null,
      success: false,
    };
  }

  /**
   * Lifecycle event.
   */
  componentDidMount() {
    this._setupWidget();
    this._setupWindowListeners();
  }

  /**
   * Lifecycle event.
   */
  componentWillUnmount() {
    this.state.widgetStep.widget.remove();
  }

  /**
   * Setup widget with its listeners.
   */
  _setupWidget = () => {
    const el = this.oktaWidgetWrapper;

    const widget = new OktaSignIn({
      baseUrl: REACT_APP_OKTA_HOST,
      clientId: REACT_APP_OKTA_CLIENT_ID,
      redirectUri: `${window.location.href.split('?')[0]}/success`,
      authParams: {
        responseType: ['id_token'],
        scopes: ['openid', 'email', 'profile'],
      },
      features: {
        autoPush: true,
        multiOptionalFactorEnroll: true,
        rememberMe: false,
      },
      processCreds: (creds, callback) => {
        console.info(`Login: Process Credential hook called.`);
        this.setState((prevState) => {
          return {
            widgetStep: {
              ...prevState.widgetStep,
              username: creds.username,
              password: creds.password,
              error: false,
              customError: null,
            },
          };
        }, () => {
          callback();
        });
      },
      language: 'en',
      i18n: {
        'en': {
          'password.error.match':ExternalMessages.getMessage('PasswordErrorMatch'),
          'password.expired.title.generic':ExternalMessages.getMessage('PasswordExpiredTitle'),
          'primaryauth.username.placeholder': ExternalMessages.getMessage('LoginUsernamePlaceholder'),
          'error.username.required': ExternalMessages.getMessage('LoginUsernameRequired'),
          'error.password.required': ExternalMessages.getMessage('LoginPasswordRequired'),
          'primaryauth.submit': ExternalMessages.getMessage('LoginSubmitButton'),
        },
      },
    });

    this.setState((prevState) => {
      return {
        widgetStep: {
          ...prevState.widgetStep,
          helplink: true,
          widget: widget,
        },
      };
    }, () => {
      // If the token was already in the localStorage of the browser, use it and redirect the user.
      this.state.widgetStep.widget.authClient.tokenManager.get('idToken')
        .then((token) => {
          // If the token is still valid, reuse it.
          if (token) {
            this._saveTokenAndRedirect(token);
          }
        })
        .catch(_ => {
          // Note: We don't care if this fails, as the ID Token entry may not exists yet.
        });

      this.state.widgetStep.widget.renderEl({ el }, this._onSuccess, this._onError);

      this.state.widgetStep.widget.on('afterRender', async (context) => {
        if(context.controller === "password-expired"){
          this.setState((prevState) => {
            return {
              widgetStep: {
                ...prevState.widgetStep,
                helplink: false,
              }
            };
          });
        }
      });

      this.state.widgetStep.widget.on('afterError', async (context, error) => {
        const { username } = this.state.widgetStep;

        // We want to always log this kind of errors.
        console.error('Login: Login error.', username, context, error);

        if (error.name === 'OAUTH_ERROR' && error.message === 'OAuth flow timed out') {
          // There is no way to reset the widget from this point other than doing a hard reset
          this._resetWidget();
        }

        let isHandled = false;

        if (error.xhr && error.xhr.responseJSON) {
          if (error.xhr.responseJSON.errorCode === 'E0000004') {
            isHandled = true;
            
            // Bad credentials.
            console.info(`Login: Login error, gathering self service configuration.`);
            this._reviewUserSelfService(username);
          } else if (error.xhr.responseJSON.errorSummary) {
            isHandled = true;

            this.setState((prevState) => {
              return {
                widgetStep: {
                  ...prevState.widgetStep,
                  customError: this._resolveCustomError(error.xhr.responseJSON.errorSummary),
                },
                blocking: false,
              };
            });
          }
        }

        // If the error wasn't handled with a custom error we display the one that is inside of the widget.
        if (!isHandled) {
          this.setState((prevState) => {
            return {
              widgetStep: {
                ...prevState.widgetStep,
                error: true,
              },
              blocking: false,
            };
          });
        }
      });
    });
  }

  /**
   * Reinitialize the widget.
   */
  _resetWidget = () => {
    this.state.widgetStep.widget.remove();
    this._setupWidget();
  }

  /**
   * Setup window listeners for the two factor providers.
   */
  _setupWindowListeners = () => {
    // We hear here for DUO message broadcasting
    window.addEventListener('message', (event) => {
      if (event.data.error === 'access_denied') {
        this.setState((prevState) => {
          return {
            widgetStep: {
              ...prevState.widgetStep,
              helplink: false,
              customError: ExternalMessages.getMessage('UserNotAssignedToApplication'),
            },
            blocking: false,
          };
        }, () => {
          // There is no way to reset the widget from this point other than doing a hard reset
          this._resetWidget();
        });
      }
    });
  }

  /**
   * Widget success handler.
   */
  _onSuccess = (res) => {
    if (res.status === 'SUCCESS') {
      this.setState({
        blocking: false,
      }, () => {
        console.info(`Login: Login succeeded, redirecting.`);
        this._saveTokenAndRedirect(res[0]);
      });
    } else {
      console.info(`Login: Login succeeded with unsuccessful state.`);
      this.setState((prevState) => {
        return {
          widgetStep: {
            ...prevState.widgetStep,
            error: true,
          },
          blocking: false,
        };
      });
    }
  }

  /**
   * Widget error handler.
   */
  _onError = (res) => {
    console.error(`Login: Error while trying to setup the widget.`);
    this.setState((prevState) => {
      return {
        widgetStep: {
          ...prevState.widgetStep,
          error: true,
        },
        blocking: false,
      };
    });
  }

  /**
   * Save the provided token and redirect to the old system.
   */
  _saveTokenAndRedirect = (idToken) => {
    this.setState((prevState) => {
      return {
        widgetStep: {
          ...prevState.widgetStep,
          idToken: idToken.idToken,
        },
      };
    }, () => {
      this._form.submit();
    });
  }

  /**
   * Show custom errors in expired password screen.
   */
  _resolveCustomError = (errorSummary) =>{
    let messageKey = null;

    // Since we cannot replace the Okta messages directly we detect what custom message we need to
    // use based on the ocurrence of some text in the existing phrases.
    if (errorSummary.indexOf('Password requirements') > -1) {
      messageKey = 'PasswordRequirements';
    } else if (errorSummary.indexOf('Old Password') > -1) {
      messageKey = 'OldPasswordNotMatch';
    } else if (errorSummary.indexOf('Password cannot be your current password') > -1) {
      messageKey = 'PasswordErrorOldNew';
    }
    
    return messageKey
      ? ExternalMessages.getMessage(messageKey)
      : errorSummary;
  }

  /**
   * Check the selfservice option for the entered username on the WidgetStep.
   */
  _reviewUserSelfService = (username) => {
    this.setState((prevState) => {
      return {
        widgetStep: {
          ...prevState.widgetStep,
          error: false,
        },
        blocking: true,
      };
    }, () => {
      const clientAuthorization = new API('ui-gatewaycontainer-react', `${REACT_APP_AUTHORIZATION_SERVICE_HOST}`);
      clientAuthorization.post('GetSelfService', {
        userName: username
      })
        .then((r) => {
          if (!r.result.selfServiceEnabled) {
            this.setState((prevState) => {
              return {
                widgetStep: {
                  ...prevState.widgetStep,
                  helplink: true,
                  customError: ExternalMessages.getMessage('InternalAuthenticationError'),
                },
                blocking: false,
              };
            });
          } else if (r.result.lockMessageEnabled) {
            this.setState((prevState) => {
              return {
                widgetStep: {
                  ...prevState.widgetStep,
                  helplink: true,
                  customError: ExternalMessages.getMessage('LockedExternalUser'),
                },
                blocking: false,
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                widgetStep: {
                  ...prevState.widgetStep,
                  helplink: true,
                  customError: ExternalMessages.getMessage('ExternalAuthenticationError'),
                },
                blocking: false,
              };
            });
          }
        })
        .catch(({ traceId }) => {
          this.setState((prevState) => {
            return {
              widgetStep: {
                ...prevState.widgetStep,
                helplink: true,
                customError: ExternalMessages.getMessage('InternalServerError', [
                  {
                    key: 'traceid',
                    value: traceId,
                  }
                ]),
              },
              blocking: false,
            };
          });
        });
    });
  }

  /**
   * Expands help links on the WidgetStep.
   */
  expandHelplink = () => {
    this.setState((prevState) => {
      return {
        forgotPasswordStep: {
          ...prevState.forgotPasswordStep,
          helplinkExpanded: !prevState.forgotPasswordStep.helplinkExpanded,
        },
      };
    });
  }

  /**
   * Change to the target step and reset to the initial state.
   */
  _changeStep = (step) => {
    this.setState((prevState) => {
      return {
        currentStep: step,
        widgetStep: {
          ...prevState.widgetStep,
          ...this.initialWidgetStepState(),
          helplink:!step === Steps.ForgotPassword,
        },
        forgotPasswordStep: {
          ...prevState.forgotPasswordStep,
          ...this.initialForgotPasswordStepState(),
          forgotPasswordError:false,
          username:null,
        },
      };
    }, () => {
      if (step === Steps.Widget) {
        // We need to reset the widget to clear the field values as there is no other way to do it.
        this._resetWidget();
      }
    });
  }

  /**
   * Handle WidgetStep link click that moves to the ForgotPasswordStep.
   */
  showForgotPassword = () => {
    this._changeStep(Steps.ForgotPassword);
  }

  /**
   * Handle ForgotPasswordStep input value change.
   */
  handleUsernameChange = (event) => {
    const newValue = event.target.value;
    
      this.setState((prevState) => {
        return {
          forgotPasswordStep: {
            ...prevState.forgotPasswordStep,
            username: newValue,
          },
        };
      });
    
  }

  /**
   * Handle button click in the ForgotPasswordStep to go back to the WidgetStep.
   */
  cancelForgotPassword = () => {
    this._changeStep(Steps.Widget);
  }

  /**
   * Gets the callback URL to redirect to after successfull login in Okta
   */
  getAction = () => {    
    let legacyAppUrl = REACT_APP_LEGACY_HOST;
    if (REACT_APP_DYNAMIC_REDIRECT === "true") {
      let search = this.props.location.search;    
      if (search) {
        const qs = querystring.parse(search); 
        if (qs && qs.redirectUrl)    
        legacyAppUrl = qs.redirectUrl;
      }
    }   
    return `${legacyAppUrl}/Handlers/OktaCallback.ashx`;
  }

  /**
   * Handle ForgotPasswordStep submit.
   */
  handleForgotPassword = () => {
    const { username } = this.state.forgotPasswordStep;
    const condition = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.+[a-zA-Z0-9-.]+$', 'g');

    if (username) {
      if (condition.test(username) === true) {
        this.setState((prevState) => {
          return {
            forgotPasswordStep: {
              ...prevState.forgotPasswordStep, 
              customError: ExternalMessages.getMessage('ForgotPasswordValidateUsername'),
            },
          };
        });
      }
      else
      {
        const clientAuthorization = new API('ui-gatewaycontainer-react', `${REACT_APP_AUTHORIZATION_SERVICE_HOST}`);
        this.setState((prevState) => {
          return {
            blocking: true,
            forgotPasswordStep: {
              ...prevState.forgotPasswordStep,
              forgotPasswordError: false,
              customError: null,
            },
          };
        }, () => {
          clientAuthorization.post('recoverypassword', {
            login: username,
          })
            .then((response) => {
              if (response.ok) {
                if(response.result !== null && !response.result.selfServiceEnabled) {
                  this.setState((prevState) => {
                    return {
                      blocking: false,
                      forgotPasswordStep: {
                        ...prevState.forgotPasswordStep,
                        customError: ExternalMessages.getMessage('ForgotPasswordInternalError'),
                      },
                    };
                  });
                }else{
                  this.setState((prevState) => {
                    return {
                      blocking: false,
                      forgotPasswordStep: {
                        ...prevState.forgotPasswordStep,
                        success: true,
                      },
                    };
                  });
                }
              } else {
                const errorMessage = response.errors.length > 0
                  ? response.errors[0].errorMessage
                  : ExternalMessages.getMessage('UnknownError');

                this.setState((prevState) => {
                  return {
                    blocking: false,
                    forgotPasswordStep: {
                      ...prevState.forgotPasswordStep,
                      customError: ExternalMessages.getMessage('ForgotPasswordServerError', [
                        {
                          key: 'message',
                          value: errorMessage,
                        },
                      ]),
                    },
                  };
                });
              }
            })
            .catch(({ traceId }) => {
              this.setState((prevState) => {
                return {
                  forgotPasswordStep: {
                    ...prevState.forgotPasswordStep,
                    customError: ExternalMessages.getMessage('InternalServerError', [
                      {
                        key: 'traceid',
                        value: traceId,
                      },
                    ]),
                  },
                  blocking: false,
                };
              });
            });
        });
      }
    } else {
      this.setState((prevState) => {
        return {
          forgotPasswordStep: {
            ...prevState.forgotPasswordStep,
            forgotPasswordError: true,
          },
        };
      });
    }
  }

  render() {
    const { blocking, currentStep, widgetStep, forgotPasswordStep } = this.state;

    // Display the error that is inside of the widget.
    const widgetError = widgetStep.error === true && widgetStep.customError === null;
    // Display custom links.
    const widgetLinks = widgetStep.helplink;
    // Display the custom error that is outside of the widget.
    const customError = widgetStep.customError !== null || forgotPasswordStep.customError !== null;
    // Display forgotPassword step only
    const forgotPassword = currentStep === Steps.ForgotPassword;

    return (
      <BlockUi tag="div" blocking={blocking}>
        <div className={classNames(
          'login',
          {
            'widgetError': widgetError,
            'widgetLinks': widgetLinks,
            'customError': customError,
            'forgotPassword': forgotPassword,
          },
        )}>
          <img className="logo" src={`${PUBLIC_URL}/images/Intellium_logo_360x75.png`} alt="Intellium Logo" />

          {customError &&
            <div className="errorBox">
              <div className="errorBox__content">
                <span className="errorBox__content__title">Validation Error</span>
                <div
                  dangerouslySetInnerHTML={{ __html: currentStep === Steps.Widget
                    ? widgetStep.customError
                    : forgotPasswordStep.customError
                  }}
                />
              </div>
            </div>
          }

          <div id="oktaWidgetWrapper" ref={ref => this.oktaWidgetWrapper = ref}></div>

          {forgotPassword &&
            <div className="forgotPasswordWrapper">
              {!forgotPasswordStep.success && (
              <div className="requestScreen">
                <div className="input-group">
                  <span>User Name</span>
                  <input type="text" onChange={this.handleUsernameChange} value={forgotPasswordStep.username} />
                  {forgotPasswordStep.forgotPasswordError && (
                    <span className="error">This field cannot be left blank</span>
                  )}
                </div>
                <button className="btn-gray" type="button" onClick={this.handleForgotPassword}>Reset Password</button>
                <button className="btn-link" type="button" onClick={this.cancelForgotPassword}>Back to Sign In</button>
              </div>
              )}

              {forgotPasswordStep.success && (
              <div className="responseScreen">
                <div
                  dangerouslySetInnerHTML={{
                    __html: ExternalMessages.getMessage('ForgotPasswordSuccess', [{
                      key: 'username',
                      value: forgotPasswordStep.username,
                    }])
                  }}
                />
                <button className="btn-gray" type="button" onClick={this.cancelForgotPassword}>Back to Sign In</button>
              </div>
              )}
            </div>
          }
          
          {widgetStep.helplink && (
          <div class="customLinks">
            <button className="btn-link" onClick={this.expandHelplink}>Need help signing in?</button>
            <ul className={classNames(
              {
                'expanded': forgotPasswordStep.helplinkExpanded,
              },
            )}>
              <li>
                <button className="btn-link" type="button" onClick={this.showForgotPassword}>
                  Forgot password?
                </button>
              </li>
            </ul>
          </div>
          )}

          <VersionDisclaimer />

          <form id="redirectForm" method="post" action={widgetStep.callbackURL} ref={form => this._form = form}>
            <input type="hidden" id="id_token" name="id_token" value={widgetStep.idToken} />
          </form>
        </div>
      </BlockUi>
    );
  }
}

export default withRouter(Login);
