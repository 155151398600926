const {
  REACT_APP_ENVIRONMENT,
} = process.env;

export const lowerEnvironments = [
  'local',
  'cd',
  'dev',
  'devtest',
  'qa',
];

export const higherEnvironments = [
  'staging',
  'prod',
];

export const isHigherEnvironment = () => {
  if (higherEnvironments.includes(REACT_APP_ENVIRONMENT)) {
    return true;
  }

  return false;
};
