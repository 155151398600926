import 'core-js/features/string';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import Root from './bootstrap/Root';
import './styles/styles.scss';

const initializationOptions = {
  caller: 'ui-gatewaycontainer-react',
  endpoints: {
    logService: process.env.REACT_APP_LOG_SERVICE_HOST,
    configService: process.env.REACT_APP_CONFIGURATION_SERVICE_HOST,
  },
  signalr: {
    forceTransport: process.env.REACT_APP_SIGNALR_FLAG === "true",
  },
  // logging: {
  //   localDebugging: true,
  //   remoteDebugging: false,
  //   logLevel: 0,
  // },
  // testMode: {
  //   enabled: false,
  //   forceClientError: false,
  //   forceServerError: false,
  //   serverExceptionType: "UnhandledException",
  // },
};

ReactDOM.render(
  <Root initializationOptions={initializationOptions} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
