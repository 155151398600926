import React from 'react';
import { Redirect } from 'react-router';
import OktaAuth from './LogoutWidget';

const {
  REACT_APP_OKTA_HOST,
} = process.env;

class Logout extends React.Component {
  componentDidMount() {
    const authClient = new OktaAuth({
      url: REACT_APP_OKTA_HOST,
    });

    authClient.tokenManager.clear();
  }

  render() {
    return (
      <div className="logout">
        Logging out...
        <Redirect to="/login" />
      </div>
    );
  }
}

export default Logout;
